import { ReactNode, useEffect, useState } from "react";
import { Section } from "../components/Section";

import Image1 from "../images/about_1.png";
import Image2 from "../images/about_2.png";
import Image3 from "../images/about_3.png";
import Image4 from "../images/about_4.png";
import Image5 from "../images/about_5.png";
import Image6 from "../images/about_6.png";
import Image9 from "../images/about_9.png";
import Image7 from "../images/about_7.png";
import Image8 from "../images/about_8.png";
import Image0 from "../images/about_0.png";
import useImagePreloader from "../hooks/useImagePreloader";

const preloadSrcList: string[] = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image0,
];

export const AboutSection = () => {
  const { imagesPreloaded } = useImagePreloader(preloadSrcList);
  const [selectedId, setSelectedId] = useState(0);
  useEffect(() => {
    if (imagesPreloaded) {
      setTimeout(
        () => setSelectedId(selectedId < 9 ? selectedId + 1 : 0),
        6000
      );
    }
  }, [selectedId, imagesPreloaded]);
  return (
    <Section dark>
      <div className="flex flex-col">
        <article className="prose max-w-none flex-1">
          <h1 className="text-primary text-6xl sm:text-7xl md:text-8xl mb-6 ">
            About
          </h1>
          <div className="flex md:flex-row-reverse flex-col items-center">
            <div className="md:w-1/3 md:ml-8 sticky top-0 bg-neutral md:relative m-0 shadow-neutral shadow-[0px_20px_5px_5px] flex w-full justify-center">
              {imagesPreloaded ? (
                <img
                  className="animate-fadeIn mb-0 max-h-[40vh]"
                  alt="an AI generated imageo matching the text"
                  src={require(`../images/about_${selectedId}.png`)}
                />
              ) : (
                <svg className="animate-spin w-10 m-8" viewBox="0 0 24 24">
                  <circle
                    className="opacity-25 "
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
            </div>
            <div className="md:w-2/3 text-lg text-justify ">
              <p>
                <Highlight
                  ready={imagesPreloaded}
                  id={0}
                  selectedId={selectedId}
                >
                  When most people think of AI
                </Highlight>
                , they imagine{" "}
                <Highlight
                  ready={imagesPreloaded}
                  id={1}
                  selectedId={selectedId}
                >
                  blade runner-esque humanoid replicants
                </Highlight>{" "}
                or{" "}
                <Highlight
                  ready={imagesPreloaded}
                  id={2}
                  selectedId={selectedId}
                >
                  an invisible network of sentient computers plotting to
                  overthrow humanity
                </Highlight>
                .{" "}
                <Highlight
                  ready={imagesPreloaded}
                  id={3}
                  selectedId={selectedId}
                >
                  AI has been a trope in sci-fi movies for so long
                </Highlight>
                , that we fail to recognize that it is already part of our
                everyday lives. From{" "}
                <Highlight
                  ready={imagesPreloaded}
                  id={4}
                  selectedId={selectedId}
                >
                  the social media algorithms keeping us glued to our screens
                </Highlight>{" "}
                to our shopping bonus cards evaluating our purchases. As{" "}
                <Highlight
                  ready={imagesPreloaded}
                  id={5}
                  selectedId={selectedId}
                >
                  this form of AI does not correspond to the sci-fi depiction we
                  are used to
                </Highlight>
                , it flies under our radar. The recent trend of{" "}
                <Highlight
                  ready={imagesPreloaded}
                  id={6}
                  selectedId={selectedId}
                >
                  using AI to generate images
                </Highlight>{" "}
                and artworks makes the previously invisible AI visceral. We aim
                to use AI-generated images to firstly show that{" "}
                <Highlight
                  ready={imagesPreloaded}
                  id={7}
                  selectedId={selectedId}
                >
                  AI is no mere sci-fi plot point
                </Highlight>{" "}
                but rather a widely used technology and secondly ask questions
                like: "How can we{" "}
                <Highlight
                  ready={imagesPreloaded}
                  id={8}
                  selectedId={selectedId}
                >
                  use it responsibly as a society
                </Highlight>
                ?" and "How are the ways it is used currently problematic and
                accelerating{" "}
                <Highlight
                  ready={imagesPreloaded}
                  id={9}
                  selectedId={selectedId}
                >
                  the meta crisis humanity is facing
                </Highlight>
                ?"
              </p>
            </div>
          </div>
        </article>
      </div>
    </Section>
  );
};

const Highlight = ({
  children,
  id,
  selectedId,
  ready,
}: {
  children: ReactNode;
  id: number;
  selectedId: number;
  ready: boolean;
}) => {
  return (
    <>
      <span className={id === selectedId && ready ? "animate-highlight" : ""}>
        {children}
      </span>
    </>
  );
};
