export const Section = ({
  children,
  dark,
  className,
}: {
  children?: React.ReactNode;
  dark?: boolean;
  className?: string;
}) => (
  <section
    className={`min-h-screen py-16 px-8 lg:px-16 xl:px-32 2xl:px-64  ${
      dark ? "bg-neutral" : ""
    } ${className}`}
  >
    {children}
  </section>
);
