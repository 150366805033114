import { Section } from "../components/Section";

export const TitleSection = () => (
  <Section>
    <div className="flex flex-col">
      <div className="flex lg:flex-row flex-col mt-32">
        <Title />
        {/*<CodeBlock className="hidden sm:block mt-8 lg:ml-8 lg:mt-0 w-full flex-1 h-fit self-end transition ease-in-out hover:translate-x-2 hover:-translate-y-2 shadow hover:shadow-xl" />*/}
      </div>
    </div>
  </Section>
);

const Title = () => (
  <article className="prose max-w-none flex-1">
    <h1 className="text-primary text-5xl sm:text-7xl md:text-8xl mb-6 ">
      Decentralized AI Collective
    </h1>
    <h2 className="text-secondary mt-0">Making the invisible viceral</h2>
  </article>
);
