import { Footer } from "./components/Footer";
import { AboutSection } from "./sections/AboutSection";
import { TitleSection } from "./sections/TitleSection";

function App() {
  return (
    <div className="flex lg:justify-center flex-col w-full">
      <TitleSection />
      <AboutSection />
      <Footer />
    </div>
  );
}

export default App;
